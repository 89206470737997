<template>
    <div class="container">
        <div class="row justify-content-start">
            <b-card class="col-sm-12 d-flex align-items-start justify-content-between">
                <div class="d-flex align-items-center">
                    <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="none" @click="goBack" v-b-tooltip.hover
                        title="Go Back to the Script List" class="mr-2">
                        <feather-icon icon="ArrowLeftCircleIcon" size="25" class="d-inline text-warning" />
                    </b-button>
                    <h3 class="mb-0">
                        {{ 'List of Scripts for Tag : ' + tag }}
                    </h3>
                </div>
            </b-card>

        </div>
        <div class="row mt-3" v-if="marketplaceData.length > 0">
            <div class="col-md-4 col-sm-6" v-for="(code, index) in marketplaceData" :key="index">
                <b-card-group>
                    <b-card :img-src="code.image_url" img-alt="Image" img-top>
                        <b-card-title style="margin-bottom: 2px !important"> {{ code.name }} </b-card-title>
                        <div class="cotainer mb-2">
                            <div class="row">
                                <div class="col-3" v-for="(tagName, index) in JSON.parse(code.tags)[0].split(',')"
                                    :key="index">

                                    <b-badge v-if="tagName === tag" @click="changeRoute(tagName)" variant="danger" pill>#{{
                                        tagName }}</b-badge>
                                    <b-badge v-else variant="primary" @click="changeRoute(tagName)" pill>#{{ tagName
                                    }}</b-badge>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center justify-content-between">
                            <p class="price text-success"> {{ price }}</p>
                            <b-link @click="getDetailCode(code.id)">
                                <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="outline-success">
                                    Preview <feather-icon icon="EyeIcon" />
                                </b-button>
                            </b-link>
                        </div>
                    </b-card>
                </b-card-group>
            </div>
        </div>
        <div class="row mt-5 justify-content-center" v-else>
            <div class="col-md-6 col-sm-12">
                <div class="text-center">
                    <feather-icon icon="AlertCircleIcon" size="80" />
                    <h3 class="my-2" style="font-size: 3rem !important;">No data available yet</h3>
                    <p class="h4">We're working on it! Check back soon.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    BContainer,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BCard,
    BRow,
    BCol,
    VBTooltip,
    BCardTitle,
    BButtonGroup,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormTextarea,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardGroup,
    BLink,
    BModal,
    VBModal,
    BFormFile,
    BCardText,
} from "bootstrap-vue";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import router from "@/router";

export default {
    components: {
        BTable,
        BAvatar,
        'b-tooltip': VBTooltip,
        BCard,
        BImg,
        BButtonGroup,
        BBadge,
        BFormTextarea,
        BRow,
        BLink,
        BCol,
        BFormGroup,
        BFormSelect,
        BFormFile,
        BPagination,
        BInputGroup,
        BCardGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        ValidationProvider,
        BCardTitle,
        ValidationObserver,
        BModal,
        VBModal,
        BCardText,
        BContainer,
    },
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data() {
        return {
            marketplaceData: [],
            price: 'Free',
            tags: [],
            tag: null,

        }
    },

    mounted() {
        this.tag = this.$route.params.tag
        this.getListCodes();
    },
    created() {
    },
    computed: {

    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        changeRoute(newTag) {
            const currentPath = this.$route.path;
            const newPath = currentPath.replace(this.tag, newTag);
            this.$router.replace(newPath);
            window.location.reload();

        },
        getDetailCode(parameter) {
            router.push({ name: "marketplaceDetail", params: { id: parameter } });
        },
        getListCodes() {
            axios.post('/get-mrktp-scripts-by-tag', {
                tag: this.tag
            })
                .then((response) => {
                    this.marketplaceData = response.data.data;
                })
                .catch((error) => {
                    console.error(error);
                })
        }
    },

}
</script>

<style scoped>
.image-container {
    overflow-x: auto;
    display: flex;
}

.image-wrapper {
    display: flex;
}

.image-item {
    flex: 0 0 auto;
    margin-right: 10px;
    max-width: 100%;
}

.image-item img {
    width: 100%;
    height: 70vh;
    /* max-width: 250px; */
}

.image-container::-webkit-scrollbar {
    width: 5px;
}

.image-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.image-container::-webkit-scrollbar-thumb {
    background-color: #050404;
    border-radius: 5px;
}

.image-container::-webkit-scrollbar-thumb:hover {
    background-color: rgb(7, 7, 60);
}

.image-container::-webkit-scrollbar-button {
    display: none;
}

del {
    font-size: 1.5rem;
    font-weight: 900;
}

#appInfoLabel {
    margin: 0;
    padding: 0;
}

#appInfoLabel .card-body {
    margin: 0;
    padding: 13px 0;
}

#appInfoMain,
#appInfoLabel {
    border-radius: 0;
}

.app-title {
    font-size: 3rem !important;
    margin-bottom: 26px;

}

.fixed-card {
    position: sticky;
    top: 20px;
}

.description-text {
    font-size: 16px;
    line-height: 1.9;
    font-weight: 400;
}

.star-rating {
    font-size: 24px;
}

.star-rating span {
    cursor: pointer;
}

.star-rating span.active {
    color: gold;
}

.ratedIcon {
    color: r#000;
}

div.app-content.content>div.content-wrapper>div>div>div:nth-child(1)>div {
    padding-block: 3px !important;
    padding-left: 3px !important;
}</style>
